<template>
  <div style="height: calc(100vh - 130px); position:relative;">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <h2 style="font-weight: 400;" class="title">Kategorien</h2>
            <v-btn
                :to="{name: 'documents.category.create'}"
                style="padding: 24px 0 25px 0;"
                elevation="2"
                color="green"
                dark
                medium
                v-if="mxware.can('document-category-create')"
            >
              <v-icon size="32">mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">{{ $t('document.category.table.id') }}</th>
                <th class="text-left">{{ $t('document.category.table.name') }}</th>
                <th class="text-left">{{ $t('document.category.table.employee') }}</th>
                <th class="text-left">{{ $t('document.category.table.customer') }}</th>
                <th class="text-left">{{ $t('document.category.table.can_delete') }}</th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="category in categories" :key="category.id">
                <td>{{ category.id }}</td>
                <td>{{ category.name }}</td>
                <td>
                  <v-chip dark x-small :color="category.employee === 1 ? 'green darken-1' : 'red darken-4'">
                    {{ $t(`document.switch.${getSwitch(category.employee)}`) }}
                  </v-chip>
                </td>
                <td>
                  <v-chip dark x-small :color="category.customer === 1 ? 'green darken-1' : 'red darken-4'">
                    {{ $t(`document.switch.${getSwitch(category.customer)}`) }}
                  </v-chip>
                </td>

                <td>
                  <v-chip dark x-small :color="category.can_delete === 1 ? 'green darken-1' : 'red darken-4'">
                    {{ $t(`document.switch.${getSwitch(category.can_delete)}`) }}
                  </v-chip>
                </td>
                <td width="150">
                  <v-btn
                      small
                      text elevation="0"
                      :to="{name: 'documents.category.edit', params: {id: category.id}}"
                      v-if="mxware.can('document-category-update')"
                  >
                    <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn v-if="category.can_delete === 1 && mxware.can('document-category-delete')" text elevation="0" small @click="dialogOpen(category)">
                    <v-icon size="16" color="red darken-4">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="500"
      >
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center; width: 100%">
              {{ $t('document.category.dialog.delete.title') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn

                color="red"
                @click="DocumentCategoryDelete"
                :loading="btn_create_loading"
                :disabled="btn_create_loading"
                width="110"
            >
              {{ $t('document.category.dialog.delete.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="dialogClose">
              {{ $t('document.category.dialog.delete.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DocumentCategories",
  data() {
    return {
      preloader: true,
      btn_create_loading: false,
      dialog: false,
      categories: [],
      active_category: null
    }
  },
  created() {
    this.$store.dispatch('documentCategories').then(() => {
      this.categories = this.$store.getters.document_categories
      this.preloader = false
    })
  },
  methods: {
    getSwitch(erg) {
      return erg === 0 ? 'no' : 'yes'
    },
    dialogOpen(category) {
      this.active_category = category
      this.dialog = true
    },
    dialogClose() {
      this.dialog = false
      this.active_category = null
      this.btn_create_loading = false
    },
    DocumentCategoryDelete() {
      this.btn_create_loading = true
      this.$store.dispatch('documentCategoryDelete', this.active_category.id).then(() =>{
        this.categories = this.categories.filter(category => category.id !== this.active_category.id)
        this.dialogClose()
      })
    }
  }
}
</script>

<style scoped>

</style>
